import styled from 'styled-components'
import { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { getCoordinates } from '../../utils/Geocoder'
import { getRequest, postRequest } from '../../utils/Api'
import { SpinnerOverlay } from '../components/dct/SpinnerOverlay'
import AnimateHeight from 'react-animate-height'
import { ErrorPopup } from '../components/dct/ErrorPopup'
import { CSSTransition } from 'react-transition-group'
import Cookies from 'js-cookie'
import TagManager from 'react-gtm-module'
import { dataLayerPush, getHost } from '../../utils/helperFunctions'
import { OptionsContext } from '../../utils/OptionsContext'

const ProgressBar = lazy(() => import('../components/dct/ProgressBar'))
const Address = lazy(() => import('../components/dct/steps/Address'))
const Results = lazy(() => import('../components/dct/steps/Results'))
const AddressConfirmation = lazy(() => import('../components/dct/steps/AddressConfirmation'))
const Consumption = lazy(() => import('../components/dct/steps/Consumption'))
const PersonalInfo = lazy(() => import('../components/dct/steps/PersonalInfo'))
const ResultConfirmation = lazy(() => import('../components/dct/steps/ResultConfirmation'))
const OfferConfirmation = lazy(() => import('../components/dct/steps/OfferConfirmation'))
const ResultsError = lazy(() => import('../components/dct/steps/ResultsError'))

const Wrapper = styled(AnimateHeight)`
  position: relative;
  margin: auto;
  max-width: 574px;
  min-height: 472px;
  box-sizing: content-box;
  padding: 60px;
  background: #f3efea;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: solid 1px #dad0ca;
  text-align: center;
  overflow: hidden;

  @media (max-width: 680px) {
    padding: 20px 10px;
    padding-bottom: 60px;
  }
`

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 300;
  font-family: 'EnzoOT', sans-serif;

  @media (max-width: 600px) {
    font-size: 24px;
    width: 300px;
    margin: auto;
    margin-bottom: 30px;
  }
`

interface ProxyAddressInterface {
  address: string
  groundarea: number
  image_type: string
  lat: number
  lon: number
  src: string
  transaction: number
  year: number
}

interface ProxyResultsInterface {
  address: string
  area: number
  background_map_url: string
  c: number
  e_consumption: number
  imgsrc: string
  inclination: number
  kwh: number
  modules: number
  modules_max: number
  modules_more: number
  orientation: number
  orientation_label: string
  savings: number
  transaction_id: number
  status: string
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

const DakCheckTool = () => {

  const queryParams = new URLSearchParams(window.location.search)
  const initialPostcode = queryParams.get('postcode')
  const initialAddress = queryParams.get('housenumber')

  const options = useContext(OptionsContext)

  const [step, setStep] = useState(1)
  const [inputAddress, setInputAddress] = useState<{ address: string, postCode: string }>({
    address: initialAddress || '',
    postCode: initialPostcode || ''
  })
  const [address, setAddress] = useState('')
  const [coordinates, setCoordinates] = useState<{ lat: number, lng: number }>({ lat: -1, lng: -1 })
  const [addressNotFound, setAddressNotFound] = useState(false)
  const [proxyAddressRes, setProxyAddressRes] = useState<ProxyAddressInterface>()
  const [proxyResults, setProxyResults] = useState<ProxyResultsInterface>()
  const [yearlySavings, setYearlySavings] = useState(0)
  const [consumption, setConsumption] = useState(0)
  const [activeGroup, setActiveGroup] = useState(0)
  const [errorPopup, setErrorPopup] = useState(false)
  const [leadsouce, setLeadsource] = useState(String(options.leadsource))

  const [pending, setPending] = useState(false)

  const [personalInfo, setPersonalInfo] = useState({ firstName: '', lastName: '', email: '' })
  const [animHeight, setAnimHeight] = useState<number | string>('auto')

  useEffect(() => {
    const host = getHost()
    const tagManagerArgs = {
      gtmId: host === 'solease.nl' ?
        String(process.env.REACT_APP_GTM_ID_LIVE) :
        host === 'dakscanzonnepanelen.nl' ?
          String(process.env.REACT_APP_GTM_ID_DAKSCAN) :
          String(process.env.REACT_APP_GTM_ID_STAGING)
    }

    TagManager.initialize(tagManagerArgs)
    window.dataLayer.push({
      event: 'dctReactLoaded',
      dctReactLoaded: 'dctReactLoaded'
    })
  }, [])

  useEffect(() => {
    if (initialPostcode && initialAddress) {
      setLocationData(initialAddress, initialPostcode)
    }
  }, [initialAddress, initialPostcode])

  useEffect(() => {
    const wrapper = document.getElementById('dct-main-wrapper')
    if (wrapper) {
      const wrapperDiv = wrapper.children[0]
      const calculatedHeight = wrapperDiv.clientHeight
      setAnimHeight(calculatedHeight || 'auto')
      if (step !== 1) {
        const bodyRect = document.body.getBoundingClientRect().top
        const elementRect = wrapper.getBoundingClientRect().top
        const elementPosition = elementRect - bodyRect
        const offsetPosition = elementPosition - 70
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }
    }
  }, [step])

  useEffect(() => {
    errorPopup && setTimeout(() => setErrorPopup(false), 3000)
  }, [errorPopup])

  const getLeadsource = () => {
    if (window.location.host.replace('www.', '') === 'praxis.nl') {
      setLeadsource('211')
    } else {
      const gfMedium = Cookies.get('gf_medium_parameter')
      options.leadsource === 179 && gfMedium && setLeadsource(gfMedium)
    }
  }

  useEffect(getLeadsource, [options.leadsource])

  const setLocationData = (address: string, postCode: string) => {
    setPending(true)
    setInputAddress({ address, postCode })
    getCoordinates(address, postCode, (lat: number, lng: number, address: string) => {
      setCoordinates({ lat: lat, lng: lng })
      setAddress(address)
      setAddressNotFound(false)
      setStep(2)
      setPending(false)
    }, () => {
      dataLayerPush('General', 'Step 2 Error')
      setAddress(`${postCode}, ${address}`)
      setAddressNotFound(true)
      setStep(2)
      setPending(false)
    })
  }

  const setProxyAddress = () => {
    setPending(true)
    const params = {
      zip: inputAddress.postCode,
      number: inputAddress.address,
      leadsource: leadsouce
    }
    getRequest('/dct/address', params, (response: ProxyAddressInterface) => {
      setProxyAddressRes(response)
      setStep(3)
      setPending(false)
    }, () => {
      setPending(false)
      setErrorPopup(true)
    })
  }

  const getProxyResults = (repetition: number) => {
    if (repetition < 25) {
      setPending(true)
      const params = {
        transaction: proxyAddressRes?.transaction,
        wait: true,
        leadsource: leadsouce
      }
      getRequest('/dct/results', params, (response: ProxyResultsInterface, code: number) => {
        if (code === 202) setTimeout(() => getProxyResults(repetition + 1), 500)
        else {
          setProxyResults(response)
          response.modules < 4 ? setStep(9) : setStep(4)
          getSavings(response.modules, response.kwh, leadsouce)
          setPending(false)
        }
      }, () => {
        setPending(false)
        setErrorPopup(true)
      })
    } else {
      setStep(9)
      setPending(false)
    }
  }

  const getSavings = (quantity: number, kwh: number, leadsource: string) => {
    getRequest('/dct/prices', {
      quantity,
      kwh,
      leadsource
    }, (response: { yearlySavings: number }) => {
      setYearlySavings(response.yearlySavings)
    }, () => {

    })
  }

  const submitRoofCheckApplication = (firstName: string, lastName: string, email: string) => {
    if (proxyResults && proxyAddressRes) {
      setPending(true)
      setPersonalInfo({ firstName, lastName, email })
      const body = {
        firstname: firstName,
        lastname: lastName,
        phone: '',
        email: email,
        postcode: inputAddress.postCode,
        consumption: consumption,
        number: inputAddress.address,
        modules: proxyResults.modules,
        kwh: proxyResults.kwh,
        background_map_url: proxyResults.background_map_url,
        imgsrc: proxyResults.imgsrc,
        src: proxyAddressRes.src,
        leadsource: leadsouce
      }
      postRequest('/dct/submit/scan', body, (response: any) => {
        dataLayerPush('Quick roofcheck', 'Step 4A roofcheck conversion')
        setStep(5)
        setPending(false)
      }, () => {
        setPending(false)
        setErrorPopup(true)
      })
    }
  }

  const submitOfferApplication = (firstName: string, lastName: string, email: string, phone: string, contract: string) => {
    setPending(true)
    const body = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
      postcode: inputAddress.postCode,
      number: inputAddress.address,
      leadsource: leadsouce,
      contractType: contract
    }
    postRequest('/dct/submit/offer', body, () => {
      step === 10 ?
        dataLayerPush('Offer form', 'Step 3B offer form conversion direct') :
        dataLayerPush('Quick roofcheck', 'Step 5A offer form conversion after roofcheck')
      setStep(11)
      setPending(false)
    }, () => {
      setPending(false)
      setErrorPopup(true)
    })
  }

  useEffect(() => {
    step === 2 ? dataLayerPush('General', 'Step 2 landing')
      : step === 3 ? dataLayerPush('Quick roofcheck', 'Step 3A landing')
        : step === 4 ? dataLayerPush('Quick roofcheck', 'Step 4A landing')
          : step === 5 ? dataLayerPush('Quick roofcheck', 'Step 5A landing')
            : step === 9 ? dataLayerPush('Quick roofcheck', 'Step 5A Error')
              : step === 10 && dataLayerPush('Offer form', 'Step 3B landing')
  }, [step])

  return (
    <Wrapper id='dct-main-wrapper' height={animHeight} duration={200}>
      {pending && <SpinnerOverlay />}
      <CSSTransition
        in={errorPopup}
        timeout={3000}
        classNames='fade'
        mountOnEnter={true}
        unmountOnExit={true}>
        <ErrorPopup />
      </CSSTransition>
      <Suspense fallback={SpinnerOverlay}>
        {errorPopup && <ErrorPopup />}
        {step === 1 && <Title>ZONNEPANELEN? DOE DE DAKSCAN</Title>}
        {step !== 1 && <ProgressBar step={step} />}
        {
          step === 1 ? <Address next={setLocationData} initialAddress={inputAddress} /> :
            step === 2 ?
              <AddressConfirmation address={address} addressNotFound={addressNotFound} coordinates={coordinates}
                                   setStep={setStep}
                                   setProxyAddress={setProxyAddress} /> :
              step === 3 ? <Consumption setStep={setStep}
                                        activeGroup={activeGroup} setActiveGroup={setActiveGroup}
                                        setConsumption={setConsumption} consumption={consumption}
                                        leadsource={leadsouce}
                                        transaction={proxyAddressRes?.transaction ? proxyAddressRes?.transaction : -1}
                                        getResults={getProxyResults} /> :
                step === 4 ?
                  <Results setStep={setStep} results={proxyResults} submitRoofCheck={submitRoofCheckApplication} /> :
                  step === 9 ?
                    <ResultsError setStep={setStep} /> :
                    step === 5 ? <ResultConfirmation setStep={setStep}
                                                     savings={yearlySavings}
                                                     submitOffer={(phone: string, contract: string) => submitOfferApplication(personalInfo.firstName, personalInfo.lastName, personalInfo.email, phone, contract)} /> :
                      step === 10 ? <PersonalInfo setStep={setStep} submitOffer={submitOfferApplication} /> :
                        step === 11 && <OfferConfirmation />

        }
      </Suspense>
    </Wrapper>
  )
}

export { DakCheckTool }
